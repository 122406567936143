import { ReactNode } from 'react';
import SideNavigation from './SideNavigation';

interface LayoutProps {
  children: ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <div className="flex min-h-screen bg-gray-900">
      <SideNavigation />
      <main className="flex-1 ml-16 p-8"> {/* Adjusted margin based on sidebar width */}
        {children}
      </main>
    </div>
  );
}