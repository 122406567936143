import React, { useState, useEffect } from 'react';
import { Target, Users, Skull, Trophy, ArrowUpCircle, Crown, PieChart, Activity } from 'lucide-react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    PieChart as RechartsBarChart,
    Pie,
    Cell
} from 'recharts';

interface PvpInteraction {
    victim: string;
    victimId: string;
    killer: string;
    killerId: string;
    timestamp: string;
}

interface KillerStats {
    name: string;
    kills: number;
    id: string;
    mostFrequentVictim?: {
        name: string;
        count: number;
    };
}

interface ApiResponse {
    data: string;
    timestamp: string;
}

const API_URL = import.meta.env.DEV 
    ? 'http://localhost:3001/api/pvp-data'
    : '/api/pvp-data';

const CHART_COLORS = ['#F59E0B', '#6366F1', '#10B981', '#EC4899', '#8B5CF6'];

const TopPlayerCard = ({ player, rank }: { player: KillerStats; rank: number }) => {
    const getBorderColor = () => {
        switch(rank) {
            case 1: return 'border-amber-500/50';
            case 2: return 'border-gray-400/50';
            case 3: return 'border-yellow-900/50';
            default: return 'border-gray-700';
        }
    };

    const getGlowEffect = () => {
        switch(rank) {
            case 1: return 'shadow-lg shadow-amber-500/20';
            case 2: return 'shadow-lg shadow-gray-400/20';
            case 3: return 'shadow-lg shadow-yellow-900/20';
            default: return '';
        }
    };

    const getIcon = () => {
        switch(rank) {
            case 1: return <Crown className="h-8 w-8 text-amber-500" />;
            case 2: return <Crown className="h-8 w-8 text-gray-400" />;
            case 3: return <Crown className="h-8 w-8 text-yellow-900" />;
            default: return null;
        }
    };

    return (
        <div className={`
            relative bg-gray-800/60 rounded-xl p-6
            border-2 ${getBorderColor()} ${getGlowEffect()}
            backdrop-blur-sm transform transition-all duration-300
            hover:scale-105 hover:bg-gray-800/80
        `}>
            <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-gray-900 rounded-full p-2">
                {getIcon()}
            </div>
            
            <div className="mt-4 flex flex-col items-center">
                <div className="w-20 h-20 bg-gray-700/50 rounded-full mb-4 flex items-center justify-center">
                    <Skull className="h-10 w-10 text-gray-500" />
                </div>
                
                <h3 className="text-xl font-bold text-white mb-2">{player.name}</h3>
                
                <div className="grid grid-cols-1 gap-3 w-full mt-4">
                    <div className="bg-gray-900/50 rounded-lg p-3 text-center">
                        <span className="text-2xl font-bold text-amber-500">{player.kills}</span>
                        <p className="text-sm text-gray-400">Total Kills</p>
                    </div>
                    
                    {player.mostFrequentVictim && (
                        <div className="bg-gray-900/50 rounded-lg p-3 text-center">
                            <span className="text-sm text-red-400">{player.mostFrequentVictim.name}</span>
                            <p className="text-xs text-gray-400 mt-1">
                                {player.mostFrequentVictim.count} kills
                            </p>
                        </div>
                    )}
                </div>

                <div className="mt-4 text-center">
                    <span className="text-xs text-gray-500">
                        #{rank} Ranked Player
                    </span>
                </div>
            </div>
        </div>
    );
};

const KillsOverTimeChart = ({ data }: { data: PvpInteraction[] }) => {
    const chartData = React.useMemo(() => {
        const timeData = new Map<string, number>();
        
        data.forEach(interaction => {
            const date = new Date(interaction.timestamp).toLocaleDateString();
            timeData.set(date, (timeData.get(date) || 0) + 1);
        });

        return Array.from(timeData.entries()).map(([date, kills]) => ({
            date,
            kills
        })).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    }, [data]);

    return (
        <div className="bg-gray-800/50 backdrop-blur-sm border border-gray-700 rounded-xl overflow-hidden p-4">
            <h2 className="text-xl font-bold text-white flex items-center gap-2 mb-4">
                <Activity className="h-5 w-5 text-amber-500" />
                Kills Over Time
            </h2>
            <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                        <XAxis 
                            dataKey="date" 
                            stroke="#9CA3AF"
                            tick={{ fill: '#9CA3AF' }}
                        />
                        <YAxis 
                            stroke="#9CA3AF"
                            tick={{ fill: '#9CA3AF' }}
                        />
                        <Tooltip
                            contentStyle={{
                                backgroundColor: '#1F2937',
                                border: 'none',
                                borderRadius: '0.5rem',
                                color: '#F3F4F6'
                            }}
                        />
                        <Line 
                            type="monotone" 
                            dataKey="kills" 
                            stroke="#F59E0B" 
                            strokeWidth={2}
                            dot={false}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

const TopKillersPieChart = ({ killers }: { killers: KillerStats[] }) => {
    const pieData = killers.slice(0, 5).map(killer => ({
        name: killer.name,
        value: killer.kills
    }));

    return (
        <div className="bg-gray-800/50 backdrop-blur-sm border border-gray-700 rounded-xl overflow-hidden p-4">
            <h2 className="text-xl font-bold text-white flex items-center gap-2 mb-4">
                <PieChart className="h-5 w-5 text-amber-500" />
                Top Killers Distribution
            </h2>
            <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                    <RechartsBarChart>
                        <Pie
                            data={pieData}
                            innerRadius={60}
                            outerRadius={80}
                            paddingAngle={5}
                            dataKey="value"
                            label
                        >
                            {pieData.map((_, index) => (
                                <Cell 
                                    key={`cell-${index}`} 
                                    fill={CHART_COLORS[index % CHART_COLORS.length]} 
                                />
                            ))}
                        </Pie>
                        <Tooltip
                            contentStyle={{
                                backgroundColor: '#1F2937',
                                border: 'none',
                                borderRadius: '0.5rem',
                                color: '#F3F4F6'
                            }}
                        />
                        <Legend />
                    </RechartsBarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

const PVPPage: React.FC = () => {
    const [pvpData, setPvpData] = useState<PvpInteraction[]>([]);
    const [topKillers, setTopKillers] = useState<KillerStats[]>([]);
    const [lastUpdate, setLastUpdate] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);

    const calculateTopKillers = (data: PvpInteraction[]): KillerStats[] => {
        const killerMap = new Map<string, KillerStats>();
        
        data.forEach(interaction => {
            // Initialize or get killer stats
            if (!killerMap.has(interaction.killerId)) {
                killerMap.set(interaction.killerId, {
                    name: interaction.killer,
                    kills: 0,
                    id: interaction.killerId
                });
            }
            
            const stats = killerMap.get(interaction.killerId)!;
            stats.kills += 1;
            
            // Count victims for each killer
            const victimCounts = data.reduce((counts, kill) => {
                if (kill.killerId === interaction.killerId) {
                    counts[kill.victimId] = {
                        name: kill.victim,
                        count: (counts[kill.victimId]?.count || 0) + 1
                    };
                }
                return counts;
            }, {} as Record<string, { name: string; count: number }>);
            
            // Find most frequent victim
            let maxCount = 0;
            let mostFrequentVictim: { name: string; count: number } | null = null;
            
            Object.entries(victimCounts).forEach(([_, victimInfo]) => {
                if (victimInfo.count > maxCount) {
                    maxCount = victimInfo.count;
                    mostFrequentVictim = victimInfo;
                }
            });
            
            if (mostFrequentVictim) {
                stats.mostFrequentVictim = mostFrequentVictim;
            }
        });

        return Array.from(killerMap.values())
            .sort((a, b) => b.kills - a.kills)
            .slice(0, 10);
    };

    const fetchPvpData = async (): Promise<void> => {
        try {
            setIsLoading(true);
            setIsError(false);
            const response = await fetch(API_URL);
            
            if (!response.ok) {
                throw new Error('Failed to fetch PVP data');
            }

            const responseData: ApiResponse = await response.json();
            
            if (!responseData.data) {
                throw new Error('No data received from server');
            }

            const parsedData: PvpInteraction[] = responseData.data
                .split('\n')
                .filter(line => line.trim() !== '' && line.includes('was killed by'))
                .map(line => {
                    const match = line.match(/(.+)\[(\d+)\] was killed by (.+)\[(\d+)\]/);
                    if (match) {
                        return {
                            victim: match[1].trim(),
                            victimId: match[2],
                            killer: match[3].trim(),
                            killerId: match[4],
                            timestamp: new Date().toISOString()
                        };
                    }
                    return null;
                })
                .filter((item): item is PvpInteraction => item !== null);

            setPvpData(parsedData);
            setTopKillers(calculateTopKillers(parsedData));
            setLastUpdate(responseData.timestamp);
        } catch (err) {
            setIsError(true);
            console.error('Error fetching PVP data:', err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPvpData();

        const now = new Date();
        const next5PM = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            17,
            0,
            0
        );
        
        if (now > next5PM) {
            next5PM.setDate(next5PM.getDate() + 1);
        }

        const timeUntilNext5PM = next5PM.getTime() - now.getTime();
        const dailyUpdateTimer = setInterval(fetchPvpData, 24 * 60 * 60 * 1000);
        const initialTimer = setTimeout(() => {
            fetchPvpData();
        }, timeUntilNext5PM);

        return () => {
            clearInterval(dailyUpdateTimer);
            clearTimeout(initialTimer);
        };
    }, []);

    if (isLoading) {
        return (
            <div className="min-h-screen flex items-center justify-center text-white bg-gray-900">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-amber-500 mb-4" />
                    <p className="text-gray-400">Loading PVP data...</p>
                </div>
            </div>
        );
    }

    if (isError) {
        return (
            <div className="min-h-screen flex items-center justify-center text-white bg-gray-900">
                <div className="bg-red-500/10 border border-red-500/20 rounded-xl p-6 max-w-md text-center">
                    <p className="text-red-400 mb-4">Failed to load PVP data</p>
                    <button
                        onClick={() => void fetchPvpData()}
                        className="px-4 py-2 bg-red-500/20 hover:bg-red-500/30 rounded-lg text-red-400 transition-colors"
                    >
                        Try Again
                    </button>
                </div>
            </div>
        );
    }

    const topThreePlayers = topKillers.slice(0, 3);

    return (
        <div className="min-h-screen text-white bg-gray-900">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="mb-12">
                    <h2 className="text-2xl font-bold text-center text-white mb-8">
                        Top Performing Players
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-5xl mx-auto">
                        {topThreePlayers.map((player, index) => (
                            <TopPlayerCard 
                                key={player.id} 
                                player={player} 
                                rank={index + 1} 
                            />
                        ))}
                    </div>
                </div>

                <div className="flex items-center justify-between mb-8">
                    <div>
                        <h1 className="text-3xl font-bold text-amber-500 flex items-center gap-2">
                            <Target className="h-8 w-8" />
                            PVP Statistics
                        </h1>
                        {lastUpdate && (
                            <p className="text-sm text-gray-400 mt-2">
                                Last updated: {new Date(lastUpdate).toLocaleString()}
                            </p>
                        )}
                    </div>
                    <div className="bg-gray-800/50 rounded-xl px-4 py-2 border border-gray-700">
                        <p className="text-sm text-gray-400">Total Kills</p>
                        <p className="text-2xl font-bold text-amber-500">{pvpData.length}</p>
                    </div>
                </div>

                {/* Charts Section */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
                    <KillsOverTimeChart data={pvpData} />
                    <TopKillersPieChart killers={topKillers} />
                </div>

                <div className="grid lg:grid-cols-3 gap-8">
                    <div className="lg:col-span-1">
                        <div className="bg-gray-800/50 backdrop-blur-sm border border-gray-700 rounded-xl overflow-hidden">
                            <div className="p-4 border-b border-gray-700">
                                <h2 className="text-xl font-bold text-white flex items-center gap-2">
                                    <Trophy className="h-5 w-5 text-amber-500" />
                                    Top Killers
                                </h2>
                            </div>
                            <div className="p-4">
                                {topKillers.map((killer, index) => (
                                    <div 
                                        key={killer.id}
                                        className="border-b border-gray-700/50 last:border-0"
                                    >
                                        <div className="flex items-center justify-between py-2">
                                            <div className="flex items-center gap-3">
                                                <span className={`
                                                    w-6 h-6 flex items-center justify-center rounded-lg
                                                    ${index === 0 ? 'bg-amber-500/20 text-amber-500' : 
                                                      index === 1 ? 'bg-gray-400/20 text-gray-400' :
                                                      index === 2 ? 'bg-yellow-900/20 text-yellow-900' :
                                                      'bg-gray-700/20 text-gray-700'}
                                                `}>
                                                    {index + 1}
                                                </span>
                                                <span className="text-white">{killer.name}</span>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <span className="text-amber-500 font-medium">{killer.kills}</span>
                                                {index === 0 && <ArrowUpCircle className="h-4 w-4 text-amber-500" />}
                                            </div>
                                        </div>
                                        {killer.mostFrequentVictim && (
                                            <div className="ml-9 text-sm pb-2">
                                                <span className="text-gray-400">Favorite Target: </span>
                                                <span className="text-red-400">{killer.mostFrequentVictim.name}</span>
                                                <span className="text-gray-500"> ({killer.mostFrequentVictim.count} kills)</span>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-2">
                        <div className="bg-gray-800/50 backdrop-blur-sm border border-gray-700 rounded-xl overflow-hidden">
                            <div className="p-4 border-b border-gray-700">
                                <h2 className="text-xl font-bold text-white flex items-center gap-2">
                                    <Users className="h-5 w-5 text-amber-500" />
                                    Recent Kills
                                </h2>
                            </div>
                            <div className="p-4">
                                <div className="space-y-4">
                                    {pvpData.map((kill, index) => (
                                        <div 
                                            key={index}
                                            className="p-4 bg-gray-700/30 rounded-lg border border-gray-700 hover:bg-gray-700/40 transition-colors"
                                        >
                                            <p className="text-white">
                                                <span className="text-amber-500 font-medium">
                                                    {kill.killer}
                                                </span> 
                                                <span className="mx-2">killed</span>
                                                <span className="text-red-400">
                                                    {kill.victim}
                                                </span>
                                            </p>
                                        </div>
                                    ))}
                                    {pvpData.length === 0 && (
                                        <p className="text-center text-gray-400 py-8">
                                            No PVP activity recorded yet
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PVPPage;