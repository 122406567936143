import { NavLink } from 'react-router-dom';
import { 
  Home,
  Database,
  Server,
  Settings,
  Users,
  Gamepad,
  MessageSquare,
  Target
} from 'lucide-react';
import type { LucideIcon } from 'lucide-react';

interface NavigationLink {
  path: string;
  label: string;
  icon: LucideIcon;
}

const navigationLinks: NavigationLink[] = [
  {
    path: '/',
    label: 'Home',
    icon: Home,
  },
  {
    path: '/pvp',
    label: 'PVP Stats',
    icon: Target,
  },
  {
    path: '/database',
    label: 'Database',
    icon: Database,
  },
  {
    path: '/servers',
    label: 'Servers',
    icon: Server,
  },
  {
    path: '/community',
    label: 'Community',
    icon: Users,
  },
  {
    path: '/play',
    label: 'Play',
    icon: Gamepad,
  },
  {
    path: '/discord',
    label: 'Discord',
    icon: MessageSquare,
  },
  {
    path: '/settings',
    label: 'Settings',
    icon: Settings,
  }
];

export default function SideNavigation() {
  return (
    <nav className="fixed left-0 top-0 h-full w-16 bg-gray-800 border-r border-gray-700 z-50">
      <div className="flex flex-col items-center py-4">
        {/* Logo */}
        <div className="mb-8">
          <NavLink 
            to="/" 
            className="flex items-center justify-center w-10 h-10 rounded-xl bg-amber-500/10 text-2xl font-bold text-amber-500 hover:bg-amber-500/20 transition-colors"
          >
            R
          </NavLink>
        </div>

        {/* Navigation Links */}
        <div className="flex flex-col space-y-2">
          {navigationLinks.map((link) => (
            <NavLink
              key={link.path}
              to={link.path}
              className={({ isActive }) => `
                p-3 rounded-xl transition-all duration-200 group relative flex items-center justify-center
                ${isActive 
                  ? 'bg-amber-500 text-black' 
                  : 'text-gray-400 hover:text-white hover:bg-gray-700/50'}
              `}
              title={link.label}
            >
              <link.icon size={20} />
              {/* Tooltip */}
              <div className="absolute left-full ml-2 px-2 py-1 bg-gray-900/95 backdrop-blur-sm text-white text-sm
                            rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible
                            transition-all duration-200 whitespace-nowrap shadow-lg border border-gray-700/50 
                            pointer-events-none z-50">
                {link.label}
              </div>
            </NavLink>
          ))}
        </div>
      </div>
    </nav>
  );
}