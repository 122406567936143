import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Suspense, lazy } from 'react';
import { Analytics } from '@vercel/analytics/react';
import Layout from './components/Layout';
import LoadingSpinner from './components/LoadingSpinner';
import ErrorBoundary from './components/ErrorBoundary';
import PVPPage from './components/PVPPage';

// Lazy load components
const HomePage = lazy(() => import('./components/HomePage'));
const ItemsDatabasePage = lazy(() => import('./components/ItemsDatabase'));
const ItemDetailsPage = lazy(() => import('./components/ItemPage'));
const ServersPage = lazy(() => import('./components/Servers'));
const DiscordPage = lazy(() => import('./components/DiscordPage'));

const pageVariants = {
  initial: {
    opacity: 0,
    y: 20
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: 'easeOut'
    }
  },
  exit: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.2,
      ease: 'easeIn'
    }
  }
};

function App() {
  const location = useLocation();

  return (
    <ErrorBoundary>
      <Layout>
        <AnimatePresence mode="wait" initial={false}>
          <motion.main
            key={location.pathname}
            initial="initial"
            animate="enter"
            exit="exit"
            variants={pageVariants}
            className="relative z-0"
          >
            <Suspense fallback={<LoadingSpinner />}>
              <Routes location={location}>
                <Route path="/" element={<HomePage />} />
                <Route path="/database" element={<ItemsDatabasePage />} />
                <Route path="/item/:id" element={<ItemDetailsPage />} />
                <Route path="/category/:category" element={<ItemsDatabasePage />} />
                <Route path="/servers" element={<ServersPage />} />
                <Route path="/discord" element={<DiscordPage />} />
                <Route path="/pvp" element={<PVPPage />} />
                <Route path="*" element={<HomePage />} />
              </Routes>
            </Suspense>
          </motion.main>
        </AnimatePresence>
        <Analytics />
      </Layout>
    </ErrorBoundary>
  );
}

export default App;