// src/components/LoadingSpinner.tsx
export default function LoadingSpinner() {
  return (
    <div className="flex items-center justify-center min-h-[60vh]">
      <div className="relative">
        <div className="w-12 h-12 border-4 border-amber-500/20 rounded-full"></div>
        <div className="w-12 h-12 border-4 border-amber-500 border-t-transparent rounded-full animate-spin absolute top-0"></div>
      </div>
    </div>
  );
}